import React from "react";
import Contact from "./Contact";
import facebook from "./facebook 1.svg"
import twitter from "./twitter.svg"
import instagram from "./instagram.svg"
import linkedin from "./linkedin.svg"
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

export default function Footer() {
  const Arrow = () => {
    return (
      <svg width="14px" height="14px" viewBox="-4.5 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g id="Dribbble-Light-Preview" transform="translate(-425.000000, -6679.000000)" fill="#fff">
            <g id="icons" transform="translate(56.000000, 160.000000)">
              <path d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519" id="arrow_right-[#333]">
              </path>
            </g>
          </g>
        </g>
      </svg>
    )
  };

  const socialMediaLinks = [
    { platform: 'Facebook', icon: facebook, link: 'https://www.facebook.com/' },
    { platform: 'Twitter', icon: twitter, link: 'https://twitter.com/' },
    { platform: 'Instagram', icon: instagram, link: 'https://www.instagram.com/' },
    { platform: 'LinkedIn', icon: linkedin, link: 'https://www.linkedin.com/' },
  ];

  return (
    <>
      <div className="w-full  py-3 md:py-7 bg-[#000] h-auto">
        <div className="container flex flex-wrap w-full h-full mt-4 lg:px-16 px-7 lg:flex-nowrap ">
          <div className="flex flex-col justify-between w-full h-full lg:mt-0">
            <div className=" text-[24px]  mb-1 text-[#fff] font-bold poppins">
              Our Clubs
            </div>
            <div className="w-full h-full pl-4 md:pl-1 contact ">
              <div className="flex flex-col justify-around w-full h-full list-disc">
                <div className="flex items-center gap-2 -ml-4 py-2 lg:py-4 text-[#fff] text-[17px] font-sans">
                  <Arrow />
                  CHEMMANKALAI DOJO
                </div>
                <div className="flex items-center gap-2 -ml-4 py-2 lg:py-4 text-[#fff] text-[17px] font-sans">
                  <Arrow />
                  MULUCODE DOJO
                </div>
                <div className="flex items-center gap-2 -ml-4 py-2 lg:py-4 text-[#fff] text-[17px] font-sans">
                  <Arrow />
                  PADANTALUMOOD DOJO
                </div>
                <div className="flex items-center gap-2 -ml-4 py-2 lg:py-4 text-[#fff] text-[17px] font-sans">
                  <Arrow />
                  KONACHERI DOJO
                </div>
              </div>
            </div>
          </div>
          <div className="w-full h-full ">
            <Contact />
          </div>
          <div className="flex md:flex-col items-center justify-center w-full gap-10 my-12 md:my-5 lg:w-[40%] lg:items-end lg:ml-0 ">
            {socialMediaLinks.map((socialMedia, index) => (
              <motion.a
                href={socialMedia.link}
                key={index}
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 0.3, delay: index * 0.2, ease: 'easeInOut' }}
                viewport={{ once: true }}
              >
                <img width={30} src={socialMedia.icon} alt={socialMedia.platform} />
              </motion.a>
            ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center w-full py-3 bg-red-600 ">
        <div className="flex flex-col md:flex-row justify-center items-center h-full text-white text-[15px]">
          © 2023 All Rights Reserved.
          <span className="md:pl-2">
            Designed by
            <Link to={"https://www.techhivetechnology.com"} target="_blank" className="text-[15px] pl-2 font-semibold">
              TechHive Technology
            </Link>
          </span>
        </div>
      </div>
    </>
  );
}
