import React, { useState } from "react";
import ClubsLayout from "../components/ClubsLayout";
import GalleryLayout from "../components/GalleryLayout";
import Banner from "../components/Banner";
import AboutUs from "../components/AboutUs";
import IconsLayout from "../components/IconsLayout";
import { motion } from "framer-motion";

export default function Home() {

  return (
    <div>
      <motion.div
        className=" bg-[#ffffff] py-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <p class="bg-gradient-to-r from-red-700 via-red-600 to-[#ffc400] bg-clip-text text-transparent text-center poppins text-[3rem] lg:text-[4.5rem] font-[700] leading-tight md:leading-normal">
          Sunshine Budokan Karate Dojos
        </p>
        <p class="text-[#040404] text-center poppins text-[2rem] pt-3 md:pt-0 md:text-[3rem] font-[700] leading-normal">
          Affiliated with KBI - Australia
        </p>
      </motion.div>
      <Banner />
      <AboutUs />
      <ClubsLayout />
      <div className="w-full h-full ">
        <GalleryLayout />
      </div>
      <IconsLayout />
    </div>
  );
}
